import React from 'react';
import styled from 'styled-components';

import { Link } from 'gatsby';
import { PRIMARY_BUTTON_COLOR, PRIMARY_TEXT_COLOR, SECONDARY_BG_COLOR, TEXT_FONT } from '../../constants';
import { CallToActionButton, MainText } from '../shared';
import { Col, Container, Row } from 'reactstrap';

const Interested = styled.div`
    font-family: ${TEXT_FONT};
    color: ${PRIMARY_TEXT_COLOR};
    margin-bottom: 40px;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 34.478px;

    @media (max-width: 768px) {
        font-size: 18px;
    }
    @media (max-height: 700px) {
        font-size: 18px;
    }
`;

const LetsGetStartedContainer = styled.div`
    background-color: ${SECONDARY_BG_COLOR};
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 40px;
    height: 100%;
`;

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 775px;
`;

const YellowDot = styled.span`
    color: ${PRIMARY_BUTTON_COLOR};
`;

export const YourFuture = () => {
    return (
        <Wrapper>
            <LetsGetStartedContainer>
                <Container className="d-flex justify-content-center flex-column">
                    <Row>
                        <Col xs={12} md={7}>
                            <MainText color={PRIMARY_TEXT_COLOR} size="48px" marginbottom="20px" margintop="0">
                                Your future starts here<YellowDot>.</YellowDot>
                            </MainText>
                            <Interested>
                                Excited to bring your project to life? Let’s connect! Drop us an email, and we’ll respond promptly to start
                                the conversation
                            </Interested>
                            <Link to={'/contact'}>
                                <CallToActionButton shadow={null} mb={'0px'} primary={true}>
                                    CONTACT US
                                </CallToActionButton>
                            </Link>
                        </Col>
                    </Row>
                </Container>
            </LetsGetStartedContainer>
        </Wrapper>
    );
};
