import React from 'react';
import styled from 'styled-components';
import { BACKGROUND_COLORS, FEATURE_COLORS, TITLE_FONT } from '../../constants';

const Wrapper = styled.div`
    height: 720px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    z-index: 10;
    padding-left: 68px;

    @media (max-width: 768px) {
        padding-left: 0;
        margin-left: 16px;
    }

    @media (min-width: 3800px) {
        padding: 0;
    }
`;

const HorizontalScroller = styled.div`
    background-color: ${BACKGROUND_COLORS.color_bg_light};
    box-shadow: 0 4px 40px 0 ${FEATURE_COLORS.shadow2};
    overflow: auto;
    display: flex;
    white-space: nowrap;
    padding: 24px;
    border-radius: 60px;
    margin-right: -68px;
    &::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;

    @media (min-width: 3800px) {
        margin: 0 auto;
    }
`;

const HorizontalContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

const Title = styled.p`
    font-family: ${TITLE_FONT};
    text-align: center;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0;
`;

const HorizontalImage = styled.img`
    height: 592px;
    border-radius: 40px;
    width: 925px;
`;

const YellowDot = styled.span`
    color: ${FEATURE_COLORS.takk_yellow};
`;

const Rectangle = styled.div``;
export const HorizontalScrollTitle = ({ sections }) => {
    return (
        <Wrapper>
            <HorizontalScroller>
                {sections.map(section => (
                    <HorizontalContainer>
                        <Title>
                            {section.title}
                            <YellowDot>.</YellowDot>
                        </Title>
                        <HorizontalImage src={section.image} alt="scrollable image" />
                    </HorizontalContainer>
                ))}
            </HorizontalScroller>
        </Wrapper>
    );
};
