import React from 'react';
import styled from 'styled-components';
import rock from '../../../static/images/case-studies/BackgroundRock.png';

const RockImage = styled.img`
    position: absolute;
    top: 30%;
    left: -70px;
    height: 60%;
    object-fit: contain;
`;

export const RockBackground = () => {
    return <RockImage src={rock} alt={'RockWallet background'} />;
};
