import React from 'react';
import styled from 'styled-components';
import { BACKGROUND_COLORS, FEATURE_COLORS } from '../../constants';

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 768px) {
        margin: 0 16px;
    }
`;

const PhoneWrapper = styled.div`
    height: 100%;
    background-color: ${BACKGROUND_COLORS.color_bg_light};
    box-shadow: 0 4px 36px 0 ${FEATURE_COLORS.shadow2};
    padding: 20px;
    border-radius: 40px;
    box-shadow: 0 4px 36px 0 ${FEATURE_COLORS.shadow2};
`;

const videoStyles = {
    width: '100%',
    height: '100%',
    maxHeight: '818px',
    borderRadius: '36px',
};
export const PhoneVideo = ({ video }) => {
    return (
        <Wrapper>
            <PhoneWrapper>
                <video autoPlay muted loop style={videoStyles}>
                    <source src={video} type="video/mp4" />
                </video>
            </PhoneWrapper>
        </Wrapper>
    );
};
