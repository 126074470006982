import React from 'react';
import styled from 'styled-components';

const EllipseImage = styled.img`
    position: absolute;
    top: ${({ top }) => `${top}%` || '30%'};
    ${({ right, offset }) => (right ? `right: -${offset}%;` : `left: -${offset}%;`)}
    width: 100%;
    height: ${({ size }) => `${size}%` || '70%'};
    object-fit: contain;
    filter: blur(100px);
    -webkit-filter: blur(100px);
    opacity: ${({ opacity }) => opacity || 0.6};
`;

export const Ellipse = ({ image, right, offset, top, size, opacity }) => {
    return <EllipseImage src={image} alt={''} right={right} offset={offset} top={top} size={size} opacity={opacity} />;
};
