import React from 'react';
import styled from 'styled-components';
import { FEATURE_COLORS, FEATURE_SHADOWS, PRIMARY_TEXT_COLOR, TITLE_FONT } from '../../constants';

const Tags = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 8px;

    @media (max-width: 1000px) {
        padding: 0 50px;
    }

    @media (max-width: 768px) {
        padding: 0 16px;
    }
`;

const Tag = styled.div`
    font-family: ${TITLE_FONT};
    background-color: ${({ color }) => (color ? FEATURE_COLORS[color] : FEATURE_COLORS.shadow)};
    color: ${PRIMARY_TEXT_COLOR};
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 4px 12px;
    border-radius: 8px;
    box-shadow: 0 5px 20px 0 ${({ color }) => (color ? FEATURE_SHADOWS[color] : FEATURE_SHADOWS.nkbm_red)};
`;

export const FeatureTags = ({ tags, color }) => {
    return (
        <Tags>
            {tags.map(tag => (
                <Tag color={color} key={tag.key}>
                    {tag}
                </Tag>
            ))}
        </Tags>
    );
};
