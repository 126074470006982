import React from 'react';
import styled from 'styled-components';
import { BACKGROUND_COLORS, FEATURE_COLORS } from '../../constants';

const Wrapper = styled.div`
    height: 720px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    z-index: 10;
    padding-left: 68px;

    @media (max-width: 768px) {
        padding-left: 0;
        margin-left: 16px;
    }

    @media (min-width: 2300px) {
        padding: 0;
    }
`;

const HorizontalScroller = styled.div`
    background-color: ${BACKGROUND_COLORS.color_bg_light};
    box-shadow: 0 4px 40px 0 ${FEATURE_COLORS.shadow2};
    overflow: auto;
    display: flex;
    gap: 13px;
    white-space: nowrap;
    padding: 24px;
    height: 676px;
    border-radius: 60px;
    margin-right: -68px;
    &::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;

    @media (min-width: 2300px) {
        margin: 0 auto;
    }
`;

const HorizontalImage = styled.img`
    height: 100%;
    border-radius: 40px;
`;

const Rectangle = styled.div``;
export const HorizontalScroll = ({ images }) => {
    return (
        <Wrapper>
            <HorizontalScroller>
                {images.map(image => (
                    <HorizontalImage src={image} alt="scrollable image" />
                ))}
            </HorizontalScroller>
        </Wrapper>
    );
};
