import React from 'react';
import styled from 'styled-components';
import print from '../../../static/images/case-studies/BackgroundPrint.png';

const PrintImage = styled.img`
    position: absolute;
    top: -25%;
    max-width: 50%;
    left: -20%;
    height: 80%;
    object-fit: contain;
`;

export const BackgroundPrint = () => {
    return <PrintImage src={print} alt={'Print background'} />;
};
